import React from 'react';
import Img from 'gatsby-image';

export interface ImageInfo {
  alt: string;
  childImageSharp?: any;
  image: any;
}

interface PreviewCompatibleImageProps {
  imageInfo: ImageInfo;
}

const PreviewCompatibleImage: React.SFC<PreviewCompatibleImageProps> = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return <Img fluid={image.childImageSharp.fluid} alt={alt} />;
  }

  if (!!childImageSharp) {
    return <Img fluid={childImageSharp.fluid} alt={alt} />;
  }

  if (!!image && typeof image === 'string') return <img src={image} alt={alt} />;

  return null;
};

export default PreviewCompatibleImage;
